import createjs from 'createjs-module';
import { Circle } from './Circle';
import { BubbleIcon } from './bubble/BubbleIcon';
import { BubbleHello } from './bubble/BubbleHello';
import { mamzelle } from '../../index';
import { BubbleOser } from './bubble/BubbleOser';
import { CanvasEventType } from '../canvas-event-values';
import { CanvasBreakpoint } from '../breakpoints-values';
import Container = createjs.Container;
import { BubbleCuriosity } from './bubble/BubbleCuriosity';
import { BubblePassionate } from './bubble/BubblePassionate';
import { randomBetweenMinAndMax } from '../../utils/addons/Math';

export class Logo extends Container {
  private bigCircle: Circle;
  private mediumCircle: Circle;
  private smallCircle: Circle;
  private bubbleContainer: Container;
  private bubbleHello: BubbleHello;
  private bubbleIcon: BubbleIcon;
  private bubbleWord: BubbleOser|BubbleCuriosity|BubblePassionate;
  private circleContainer: Container;

  private screen: CanvasBreakpoint = CanvasBreakpoint.SMALL;

  public constructor() {
    super();
    this.drawVector();
    this.bindEvents();
  }

  private drawVector(): void {
    this.drawCircles();
    this.drawBubbles();
  }

  private bindEvents(): void {
    this.bindWindowResize();
    this.bindLogoAnimationIn();
  }

  private bindWindowResize(): void {
    this.setScreen();

    window.addEventListener('resize', () => {
      this.setScreen();
      this.setBubbleContainerPosition();
      this.setCircleContainerPosition();
    });
  }

  private setScreen() {
    let windowWidth = window.innerWidth;

    if (windowWidth <= CanvasBreakpoint.SMALL) {
      this.screen = CanvasBreakpoint.XSMALL;
    } else if (windowWidth > CanvasBreakpoint.SMALL && windowWidth < 845) {
      this.screen = CanvasBreakpoint.SMALL;
    } else {
      this.screen = 'default';
    }
  }

  private bindLogoAnimationIn(): void {
    document.addEventListener(CanvasEventType.LOGO_CIRCLE_ANIMATION_IN, (event) => {
      this.circleAnimationIn();
    });
  }

  private getBubblePosition() {
    switch (this.screen) {
      case CanvasBreakpoint.XSMALL:
        return {x: 270, y: -267};
        break;

      case CanvasBreakpoint.SMALL:
        return {x: 414, y: -190};
        break;

      default:
        return {x: 427, y: -151};
    }
  }

  private getCircleContainer() {
    switch (this.screen) {
      case CanvasBreakpoint.XSMALL:
        return {x: 80, y: 0};
        break;

      default:
        return {x: 0, y: 0};
        break;
    }
  }

  private setCircleContainerPosition(): void {
    const position = this.getCircleContainer();

    this.circleContainer.x = position.x;
    this.circleContainer.y = position.y;
  }

  private setBubbleContainerPosition(): void {
    const position = this.getBubblePosition();

    this.bubbleContainer.x = position.x;
    this.bubbleContainer.y = position.y;
  }

  private drawBubbles(): void {
    this.bubbleContainer = new Container();
    this.setBubbleContainerPosition();
    this.addChild(this.bubbleContainer);

    this.bubbleIcon = new BubbleIcon();
    this.bubbleIcon.scaleX = this.bubbleIcon.scaleY = 0.42
    this.bubbleIcon.x = 266;
    this.bubbleIcon.y = 34;
    this.bubbleIcon.alpha = 0;


    this.bubbleHello = new BubbleHello();
    this.bubbleHello.x = 0;
    this.bubbleHello.y = 0;
    this.bubbleHello.alpha = 0;


    this.createBubbleWord();

    this.bubbleContainer.addChild(this.bubbleIcon, this.bubbleWord, this.bubbleHello);

    //mamzelle.Addons.dragInfos.addDrag(this.bubbleContainer);
  }

  private createBubbleWord() {
    const index = randomBetweenMinAndMax(1,3);

    switch(index) {
      case 1:
        this.createBubbleOser();
        break;
      case 2:
        this.createBubbleCuriosity();
        break;
      case 3:
        this.createBubblePassionate();
        break;
    }
  }

  private createBubbleOser() {
    this.bubbleWord = new BubbleOser();
    this.bubbleWord.x = 179;
    this.bubbleWord.y = 104;
    this.bubbleWord.alpha = 0;
  }

  private createBubbleCuriosity() {
    this.bubbleWord = new BubbleCuriosity();
    this.bubbleWord.x = 189;
    this.bubbleWord.y = 104;
    this.bubbleWord.alpha = 0;
  }

  private createBubblePassionate() {
    this.bubbleWord = new BubblePassionate();
    this.bubbleWord.x = 189;
    this.bubbleWord.y = 104;
    this.bubbleWord.alpha = 0;
  }

  private drawCircles(): void {
    this.circleContainer = new Container();
    this.setCircleContainerPosition();

    this.bigCircle = new Circle();
    this.bigCircle.x = 353;
    this.bigCircle.y = 131;
    this.bigCircle.scaleX = this.bigCircle.scaleY = 0;

    this.mediumCircle = new Circle();
    this.mediumCircle.x = 239;
    this.mediumCircle.y = -9;
    this.mediumCircle.scaleX = this.mediumCircle.scaleY = 0;

    this.smallCircle = new Circle();
    this.smallCircle.x = this.smallCircle.y = 130;
    this.smallCircle.scaleX = this.smallCircle.scaleY = 0;

    this.addChild(this.circleContainer);
    this.circleContainer.addChild(this.mediumCircle, this.smallCircle, this.bigCircle);
    //mamzelle.Addons.dragInfos.addDrag(this.bigCircle);
  }

  private circleAnimationIn(): void {
    const duration: number = 750;
    createjs.Tween.get(this.bigCircle).to({scaleX: 1, scaleY: 1}, duration, createjs.Ease.quartInOut);
    createjs.Tween.get(this.mediumCircle).wait(250).to({scaleX: 0.6, scaleY: 0.6}, duration, createjs.Ease.quartInOut);
    createjs.Tween.get(this.smallCircle).wait(550).to({scaleX: 0.5, scaleY: 0.5}, duration, createjs.Ease.quartInOut);
    createjs.Tween.get(this.bubbleWord).wait(1000).to({alpha: 1}, 600, createjs.Ease.quartInOut);
    createjs.Tween.get(this.bubbleHello).wait(1300).to({alpha: 1}, 600, createjs.Ease.quartInOut);
    createjs.Tween.get(this.bubbleIcon).wait(1500).to({alpha: 1, y: 35}, 600, createjs.Ease.quartInOut);


    const durationFloating = 900;
    const durationFloatingHello = 1000;
    const durationFloatingIcon = 950;
    const easingFloating = null;
    createjs.Tween.get(this.bubbleHello, {loop: true}).to({y: 2}, durationFloatingHello, easingFloating).to({y: 4}, durationFloatingHello, easingFloating).to({y: 0}, durationFloatingHello, easingFloating);
    createjs.Tween.get(this.bubbleWord, {loop: true}).to({y: 102}, durationFloating, easingFloating).to({y: 106}, durationFloating, easingFloating).to({y: 104}, durationFloating, easingFloating);
    createjs.Tween.get(this.bubbleIcon, {loop: true}).to({y: 32}, durationFloatingIcon, easingFloating).to({y: 36}, durationFloatingIcon, easingFloating).to({y: 34}, durationFloatingIcon, easingFloating);


    const rotationDuration = 200000;
    const loop = -1;
    const waitDuration = 400;
    createjs.Tween.get(this.bigCircle).wait(waitDuration).to({rotation: 360, loop: loop}, rotationDuration);
    createjs.Tween.get(this.smallCircle).wait(waitDuration).to({rotation: 360, loop: loop}, rotationDuration);
    createjs.Tween.get(this.mediumCircle).wait(waitDuration).to({rotation: 360, loop: loop}, rotationDuration);

  }
}


