import createjs from 'createjs-module';
import Shape = createjs.Shape;
import Container = createjs.Container;
import { convertRGBAToHex, convertRGBToRGBA } from '../../../utils/Color/Color';
import { ColorRGB } from '../../../utils/Color/color-value';
import { mamzelle } from '../../../index';


export class BubblePassionate extends Container {

  public constructor() {
    super();
    this.drawVector();
  }

  private drawVector(): void {
    this.drawBubble();
    this.drawText();
  }

  private drawBubble() {
    const dropShadowColor = convertRGBAToHex(convertRGBToRGBA(ColorRGB.BLACK,0.1));

    const shape = new createjs.Shape();
    shape.graphics.beginLinearGradientFill(["#AE6529","#AF6529","#CD802C","#E59830","#F8A932","#FFB033"],[0,0.004,0.275,0.533,0.78,1],0,55.4,0,-55.3).s().p("AkMGHInSAAQgnAAgcgcQgcgcAAgoIAArwQAAgoAcgcQAcgcAnAAIW9AAQAnAAAcAcQAcAcAAAoIAALwQAAAogcAcQgcAcgnAAIqIAAImtCjg");
    shape.setTransform(93.025,55.375);
    shape.shadow = new createjs.Shadow(dropShadowColor, 3, 3, 20);

    this.addChild(shape);
  }

  private drawText(): void {
    const container = new createjs.Container();

    const shape = new createjs.Shape();
    shape.graphics.f("#FFFFFF").s().p("AgOA9QAJgNAGgSQAGgPAAgNQAAgJgCgHIgFgSIgGgQIgIgMIAHgDQAIAOAHARQAHAQAAARQAAAKgDALQgDANgFAJIgLAVg");
    shape.setTransform(106.475,40.6);

    const shape_1 = new createjs.Shape();
    shape_1.graphics.f("#FFFFFF").s().p("AgWAqQgIgEgDgGQgEgHAAgHQAAgIAFgFQAEgFAJgEQAIgDALAAIANABIANAEIAAgJQAAgMgHgHQgIgHgLAAQgFAAgIACQgHADgIAFIgDgGQAJgGAIgDQAIgCAGAAQAQAAAJAJQAJAJAAAQIAAAqIABADIADACIAAAGIgDABIgCAAQgCgBgCgCQAAgBgBAAQAAgBAAAAQgBgBAAgBQAAAAAAgBIAAgHQgGAIgKAEQgJAEgKAAQgHAAgHgDgAgUAEQgJAFAAAJQAAAFADAFQADAFAFADQAGACAGAAQAJAAAHgDQAKgFADgFIACgEIABgDIAAgPQgIgDgFAAQgGgBgHAAQgMAAgIAFg");
    shape_1.setTransform(100.1,42.175);

    const shape_2 = new createjs.Shape();
    shape_2.graphics.f("#FFFFFF").s().p("AgCA6QgDgCgDgEQgDgCABgGIAAhCIgNAAIAAgHIANAAIAAgeIAIAAIAAAeIATAAIAAAHIgTAAIAABAQAAAFACACQAEADAEAAQAGAAADgCIAEgCIADAGIgEACIgGADIgIABQgGAAgCgCg");
    shape_2.setTransform(93.15,40.725);

    const shape_3 = new createjs.Shape();
    shape_3.graphics.f("#FFFFFF").s().p("AgXAqQgGgEgEgGQgEgHAAgHQAAgIAFgFQAEgFAJgEQAJgDAKAAIANABIANAEIAAgJQAAgMgHgHQgHgHgMAAQgGAAgHACQgHADgHAFIgEgGQAIgGAIgDQAJgCAHAAQAPAAAJAJQAJAJAAAQIAAAqIABADIADACIAAAGIgCABIgDAAQgCgBgCgCQgBgBAAAAQAAgBgBAAQAAgBAAgBQAAAAAAgBIAAgHQgGAIgKAEQgJAEgKAAQgHAAgIgDgAgVAEQgIAFAAAJQAAAFADAFQADAFAFADQAGACAGAAQAJAAAIgDQAHgEAEgGIADgEIABgDIAAgPIgNgDQgGgBgHAAQgMAAgJAFg");
    shape_3.setTransform(85.65,42.175);

    const shape_4 = new createjs.Shape();
    shape_4.graphics.f("#FFFFFF").s().p("AAcAsIAAgvQgBgSgFgHQgEgIgLAAQgHAAgGAEQgHAEgGAFQgFAHgDAHIAAA1IgIAAIAAhWIAIAAIAAAVQADgGAGgGQAFgEAJgEQAHgCAHAAQAHAAAFACQAFADADAFQADAEABAIQACAHAAAJIAAAxg");
    shape_4.setTransform(76.45,42.075);

    const shape_5 = new createjs.Shape();
    shape_5.graphics.f("#FFFFFF").s().p("AgQA5QgIgDgGgHQgFgGgEgIQgDgKAAgHQAAgJADgIQAEgIAFgGQAFgGAJgEQAIgEAIAAQAKAAAHAEQAIAEAGAGQAFAGAEAIQADAGAAALQAAAJgDAIQgEAIgFAGQgGAGgIAEQgJAEgIAAQgHAAgJgEgAgQgQQgIAFgFAJQgEAHAAALQAAAKAEAIQAFAJAIAFQAIAFAIAAQAJAAAIgFQAIgFAFgJQAEgIAAgLQAAgKgEgIQgFgIgIgFQgHgFgKAAQgJAAgHAFgAgXg1IAAgHIAvAAIAAAHg");
    shape_5.setTransform(66.55,40.625);

    const shape_6 = new createjs.Shape();
    shape_6.graphics.f("#FFFFFF").s().p("AgDA+IAAhXIAHAAIAABXgAgDgtIAAgQIAHAAIAAAQg");
    shape_6.setTransform(59.825,40.35);

    const shape_7 = new createjs.Shape();
    shape_7.graphics.f("#FFFFFF").s().p("AgRAqQgKgEgGgHIAEgGQAIAHAGADQAJADAHAAQAKAAAIgFQAHgEAAgJQAAgGgDgDQgDgCgHgDIgOgEQgKgCgFgCQgGgCgDgEQgDgEAAgHQAAgKAEgEQAEgFAIgEQAGgCAJAAQALAAAHADQAIAEAEAFIgFAFQgEgFgHgDQgHgCgHAAQgFAAgGABQgEABgFAFQgDAEAAAGQAAAFADADQACADAFABIAdAIQAHADAEAEQAEAFAAAHQAAANgJAGQgJAHgPAAQgIAAgKgDg");
    shape_7.setTransform(53.975,42.175);

    const shape_8 = new createjs.Shape();
    shape_8.graphics.f("#FFFFFF").s().p("AgRAqQgKgEgGgHIAEgGQAHAHAHADQAKADAFAAQALAAAIgFQAHgEAAgJQAAgGgDgDQgDgCgHgDIgNgEIgQgEQgGgCgDgEQgDgEAAgHQAAgJAEgFQAEgFAIgEQAHgCAIAAQAKAAAIADQAHADAFAGIgFAFQgFgGgFgCQgIgCgIAAQgEAAgGABQgGACgDAEQgDADAAAHQAAAEADAEQACADAFABIALAEIARAEQAIADAEAEQAEAEAAAIQAAANgJAGQgJAHgPAAQgIAAgKgDg");
    shape_8.setTransform(45.9,42.175);

    const shape_9 = new createjs.Shape();
    shape_9.graphics.f("#FFFFFF").s().p("AgXAqQgGgEgEgGQgEgHAAgHQAAgIAEgFQAGgFAIgEQAIgDALAAIANABIANAEIAAgJQAAgMgHgHQgHgHgMAAQgGAAgHACQgJAEgFAEIgEgGQAIgGAIgDQAJgCAHAAQAPAAAJAJQAJAJAAAQIAAAqIABADIADACIAAAGIgCABIgCAAQgDgBgDgCQAAgBAAAAQAAgBgBAAQAAgBAAgBQAAAAAAgBIAAgHQgIAIgIAEQgJAEgKAAQgHAAgIgDgAgVAEQgIAFAAAJQAAAFADAFQADAFAFADQAGACAGAAQAJAAAHgDQAJgEADgGIADgEIABgDIAAgPIgNgDQgHgBgGAAQgMAAgJAFg");
    shape_9.setTransform(37.25,42.175);

    const shape_10 = new createjs.Shape();
    shape_10.graphics.f("#FFFFFF").s().p("AgpA8IAAh3IAxAAQAHAAAGADQAHAEAEAFQAGAFABAHQADAFAAAIQAAALgEAHQgFAIgHAFQgIAGgJAAIgqAAIAAAtgAghAHIApAAQAIAAAFgEQAHgEACgGQADgHAAgIQAAgGgDgIQgEgGgGgFQgGgEgHAAIgoAAg");
    shape_10.setTransform(28.15,40.525);

    const shape_11 = new createjs.Shape();
    shape_11.graphics.f("#FFFFFF").s().p("AgDAsQgGgNgCgJQgDgKAAgLQAAgRAHgQQAHgPAIgQIAHADIgIAMIgHAQIgEASQgCAHAAAJQAAALAGARQAFAQAKAPIgHAEg");
    shape_11.setTransform(20.325,40.6);

    const shape_12 = new createjs.Shape();
    shape_12.graphics.f("#FFFFFF").s().p("AgxB6IAAjzIBjAAIAAAiIg+AAIAABDIAsAAIAAAgIgsAAIAABMIA+AAIAAAig");
    shape_12.setTransform(122.325,12.55);

    const shape_13 = new createjs.Shape();
    shape_13.graphics.f("#FFFFFF").s().p("AgSB6IAAjRIgmAAIAAgiIBxAAIAAAiIgnAAIAADRg");
    shape_13.setTransform(109.725,12.55);

    const shape_14 = new createjs.Shape();
    shape_14.graphics.f("#FFFFFF").s().p("AAbB6IgIg2IgnAAIgIA2IgkAAIAojzIAvAAIAqDzgAgOAiIAbAAIgOhgg");
    shape_14.setTransform(97.15,12.55);

    const shape_15 = new createjs.Shape();
    shape_15.graphics.f("#FFFFFF").s().p("AAaB6Ig0iVIAACVIgiAAIAAjzIAmAAIAwCQIAAiQIAjAAIAADzg");
    shape_15.setTransform(82,12.55);

    const shape_16 = new createjs.Shape();
    shape_16.graphics.f("#FFFFFF").s().p("AgqBqQgIgKgDgOQgDgMAAgbIAAhVQAAgbADgMQADgNAIgLQAQgUAaAAQAcAAAPAUQAIALADANQADAMAAAbIAABVQAAAbgDAMQgDAOgIAKQgQAUgbgBQgZABgRgUgAgShKQgCAIAAAdIAABLQAAAfADAJQAEANANAAQAPABAEgRQACgJAAgcIAAhLQAAgfgDgJQgEgOgOABQgOgBgEARg");
    shape_16.setTransform(67.075,12.55);

    const shape_17 = new createjs.Shape();
    shape_17.graphics.f("#FFFFFF").s().p("AgRB6IAAjzIAjAAIAADzg");
    shape_17.setTransform(56.375,12.55);

    const shape_18 = new createjs.Shape();
    shape_18.graphics.f("#FFFFFF").s().p("AgpBrQgQgRgBgeIAigHQADAmAWAAQAKAAAHgHQAGgGAAgNQAAgNgKgOQgIgJgTgUQgXgUgJgQQgKgRAAgUQAAgbAQgRQAQgRAYAAQAxABAHA6IgkAFQgCgRgEgGQgFgGgKAAQgIAAgGAGQgFAHAAALQAAAMAGAKQAHAKASASQAdAZAJAQQAJAQAAAWQAAAcgQARQgQARgaAAQgZAAgRgSg");
    shape_18.setTransform(46.125,12.55);

    const shape_19 = new createjs.Shape();
    shape_19.graphics.f("#FFFFFF").s().p("AgpBrQgQgRgBgeIAigHQADAmAWAAQAKAAAHgHQAGgGAAgNQAAgNgKgOQgIgJgTgUQgYgUgJgQQgJgRAAgUQAAgbAQgRQAQgRAYAAQAxABAHA6IgkAFQgCgRgEgGQgFgGgKAAQgJAAgFAGQgGAHAAALQAAAMAHAKQAGAJATATQAcAZAKAQQAJAQAAAWQAAAcgQARQgQARgaAAQgZAAgRgSg");
    shape_19.setTransform(32.575,12.55);

    const shape_20 = new createjs.Shape();
    shape_20.graphics.f("#FFFFFF").s().p("AAbB6IgIg2IgmAAIgJA2IgkAAIAojzIAvAAIAqDzgAgOAiIAbAAIgNhgg");
    shape_20.setTransform(18.475,12.55);

    const shape_21 = new createjs.Shape();
    shape_21.graphics.f("#FFFFFF").s().p("Ag4B6IAAjzIAzAAQAiAAAOAQQAOAQAAAnQAAAogOAPQgOAQgiAAIgPAAIAABlgAgUgMIAMAAQARAAAGgHQAGgIAAgXQAAgWgGgIQgGgHgRAAIgMAAg");
    shape_21.setTransform(5.725,12.55);

    container.addChild(shape, shape_1,shape_2, shape_3, shape_4, shape_5, shape_6, shape_7, shape_8, shape_9, shape_10, shape_11, shape_12, shape_13, shape_14, shape_15, shape_16, shape_17, shape_18, shape_19, shape_20, shape_21);

    container.x = 32;
    container.y = 26;
    this.addChild(container);
  }
}
