import createjs from 'createjs-module';
import Shape = createjs.Shape;

export interface BubbleOpts{
  colorStart: string;
  colorEnd: string;
}

export class Bubble extends Shape {

  public constructor(opts:BubbleOpts) {
    super();
    this.drawVector(opts);
  }

  private drawVector(opts: BubbleOpts): void {
    this.graphics.beginLinearGradientFill([opts.colorStart,opts.colorEnd],[0,1],0,-85.4,0,85.4).beginStroke().moveTo(-41.4,60.3).lineTo(-91.8,60.3).curveTo(-97.9,60.2,-102.2,55.9).curveTo(-106.5,51.7,-106.5,45.5).lineTo(-106.5,-70.8).curveTo(-106.5,-76.8,-102.2,-81.1).curveTo(-97.9,-85.5,-91.8,-85.4).lineTo(91.8,-85.4).curveTo(97.9,-85.5,102.2,-81.1).curveTo(106.5,-76.8,106.5,-70.8).lineTo(106.5,45.5).curveTo(106.5,51.7,102.2,55.9).curveTo(97.9,60.2,91.8,60.3).lineTo(13.4,60.3).lineTo(-52.9,85.5).closePath();
    this.setTransform(106.475,85.45);
  }
}
