import createjs from 'createjs-module';
import Shape = createjs.Shape;
import Container = createjs.Container;
import { convertRGBAToHex, convertRGBToRGBA } from '../../../utils/Color/Color';
import { ColorRGB } from '../../../utils/Color/color-value';
import { mamzelle } from '../../../index';

export class BubbleHello extends Container {

  public constructor() {
    super();
    this.drawVector();
  }

  private drawVector(): void {
    this.drawBubble();
    this.drawText();
  }

  private drawBubble() {
    const dropShadowColor = convertRGBAToHex(convertRGBToRGBA(ColorRGB.BLACK,0.1));

    const shape = new createjs.Shape();
    shape.graphics.beginLinearGradientFill(["#8AD7F5","#009ED3"],[0,0.7],0,-85.4,0,85.4).beginStroke().moveTo(-41.4,60.3).lineTo(-91.8,60.3).curveTo(-97.9,60.2,-102.2,55.9).curveTo(-106.5,51.7,-106.5,45.5).lineTo(-106.5,-70.8).curveTo(-106.5,-76.8,-102.2,-81.1).curveTo(-97.9,-85.5,-91.8,-85.4).lineTo(91.8,-85.4).curveTo(97.9,-85.5,102.2,-81.1).curveTo(106.5,-76.8,106.5,-70.8).lineTo(106.5,45.5).curveTo(106.5,51.7,102.2,55.9).curveTo(97.9,60.2,91.8,60.3).lineTo(13.4,60.3).lineTo(-52.9,85.5).closePath();
    shape.setTransform(106.475,85.45);
    shape.shadow = new createjs.Shadow(dropShadowColor, 3, 3, 20);

    this.addChild(shape);
  }

  private drawText() {
    const container = new createjs.Container();

    const shape = new createjs.Shape();
    shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-2.3,11.9).curveTo(-3.3,11.5,-4.1,10.5).curveTo(-4.8,9.8,-5.4,8.5).curveTo(-5.8,7.2,-5.8,5.8).lineTo(-2.4,5.3).curveTo(-2.3,9,0.1,9).curveTo(1.2,9,1.7,8.3).curveTo(2.4,7.6,2.4,6.4).curveTo(2.4,5.1,1.4,3.8).lineTo(0.6,2.8).lineTo(-1.4,1).curveTo(-2.6,-0.2,-3.4,-1).curveTo(-4.2,-2,-4.6,-2.8).curveTo(-5.1,-3.6,-5.3,-4.5).curveTo(-5.5,-5.4,-5.5,-6.4).curveTo(-5.5,-9.1,-4,-10.7).curveTo(-2.4,-12.4,0.1,-12.4).curveTo(4.9,-12.4,5.6,-6.6).lineTo(2.1,-6.1).curveTo(1.8,-7.7,1.5,-8.4).curveTo(1,-9,0,-9).curveTo(-1,-9,-1.5,-8.4).curveTo(-2,-7.6,-2,-6.6).curveTo(-2.1,-6,-1.9,-5.5).lineTo(-1.4,-4.4).lineTo(-0.4,-3.2).lineTo(3.5,0.6).curveTo(4.3,1.6,4.9,2.5).curveTo(5.4,3.4,5.6,4.2).curveTo(5.9,5.5,5.9,6.2).curveTo(5.9,7.6,5.4,8.7).curveTo(5,9.9,4.3,10.7).curveTo(3.4,11.5,2.5,11.9).curveTo(1.4,12.4,0.1,12.4).curveTo(-1.1,12.4,-2.3,11.9).closePath();
    shape.setTransform(114.9,72.75);

    const shape_1 = new createjs.Shape();
    shape_1.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.8,12).lineTo(-1.8,-12).lineTo(1.8,-12).lineTo(1.8,12).closePath();
    shape_1.setTransform(104.85,72.75);

    const shape_2 = new createjs.Shape();
    shape_2.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-4.9,12).lineTo(-4.9,-12).lineTo(4.9,-12).lineTo(4.9,-8.7).lineTo(-1.3,-8.7).lineTo(-1.3,-2.2).lineTo(3.2,-2.2).lineTo(3.2,1.2).lineTo(-1.3,1.2).lineTo(-1.3,8.6).lineTo(4.9,8.6).lineTo(4.9,12).closePath();
    shape_2.setTransform(90.475,72.75);

    const shape_3 = new createjs.Shape();
    shape_3.graphics.beginFill("#FFFFFF").beginStroke().moveTo(4.4,12).lineTo(4.4,-4.6).lineTo(0.9,12).lineTo(-0.7,12).lineTo(-4.3,-4.6).lineTo(-4.3,12).lineTo(-7.7,12).lineTo(-7.7,-12).lineTo(-2.9,-12).lineTo(0.1,2.6).lineTo(2.9,-12).lineTo(7.7,-12).lineTo(7.7,12).closePath();
    shape_3.setTransform(74.625,72.75);

    const shape_4 = new createjs.Shape();
    shape_4.graphics.beginFill("#FFFFFF").beginStroke().moveTo(2.7,12).lineTo(1.9,6.7).lineTo(-2,6.7).lineTo(-2.8,12).lineTo(-6.4,12).lineTo(-2.5,-12).lineTo(2.2,-12).lineTo(6.4,12).closePath().moveTo(-1.5,3.3).lineTo(1.3,3.3).lineTo(-0.1,-6.2).closePath();
    shape_4.setTransform(58.075,72.75);

    const shape_5 = new createjs.Shape();
    shape_5.graphics.beginFill("#FFFFFF").beginStroke().moveTo(2.6,12).lineTo(-2.6,-2.8).lineTo(-2.6,12).lineTo(-6,12).lineTo(-6,-12).lineTo(-2.4,-12).lineTo(2.6,2.2).lineTo(2.6,-12).lineTo(5.9,-12).lineTo(5.9,12).closePath();
    shape_5.setTransform(42.95,72.75);

    const shape_6 = new createjs.Shape();
    shape_6.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.7,12).lineTo(-1.7,2.3).lineTo(-6.2,-12).lineTo(-2.5,-12).lineTo(0.1,-2.6).lineTo(2.6,-12).lineTo(6.2,-12).lineTo(1.9,2.3).lineTo(1.9,12).closePath();
    shape_6.setTransform(23.725,72.75);

    const shape_7 = new createjs.Shape();
    shape_7.graphics.beginFill("#FFFFFF").beginStroke().moveTo(4.4,12).lineTo(4.4,-4.6).lineTo(0.9,12).lineTo(-0.7,12).lineTo(-4.4,-4.6).lineTo(-4.4,12).lineTo(-7.7,12).lineTo(-7.7,-12).lineTo(-2.9,-12).lineTo(0.1,2.6).lineTo(2.8,-12).lineTo(7.7,-12).lineTo(7.7,12).closePath();
    shape_7.setTransform(7.725,72.75);

    const shape_8 = new createjs.Shape();
    shape_8.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-4.9,24.1).curveTo(-7.1,23,-8.5,21.3).curveTo(-9.4,20.2,-10.1,19).curveTo(-10.5,18,-11,16.5).curveTo(-11.3,15,-11.4,13.1).lineTo(-11.4,-13.1).curveTo(-11.3,-15,-11,-16.5).curveTo(-10.5,-18,-10.1,-19).curveTo(-9.4,-20.2,-8.5,-21.2).curveTo(-7.1,-23.1,-4.9,-24).curveTo(-2.6,-25.1,-0.1,-25.1).curveTo(2.5,-25.1,4.8,-24).curveTo(7,-23.1,8.5,-21.2).curveTo(9.2,-20.3,9.9,-19).curveTo(10.4,-17.9,10.7,-16.5).curveTo(11.1,-15.2,11.3,-13.1).curveTo(11.4,-11.2,11.4,-8.5).lineTo(11.4,8.5).curveTo(11.4,11.2,11.3,13.2).curveTo(11.1,15.2,10.7,16.5).curveTo(10.4,17.9,9.9,19).curveTo(9.1,20.4,8.5,21.3).curveTo(7,23,4.8,24.1).curveTo(2.3,25.1,-0.1,25.1).curveTo(-2.6,25.1,-4.9,24.1).closePath().moveTo(-3.8,-14.8).curveTo(-4,-14.2,-4,-12.3).lineTo(-4,13.1).curveTo(-4,14.8,-3.6,15.6).curveTo(-2.6,18.3,0,18.3).curveTo(2.9,18.3,3.7,14.9).curveTo(4,13.7,4,7.7).lineTo(3.9,-13.1).curveTo(3.7,-14.9,3.5,-15.6).curveTo(2.5,-18.2,0,-18.2).curveTo(-3.1,-18.2,-3.8,-14.8).closePath();
    shape_8.setTransform(109.7,25.1);

    const shape_9 = new createjs.Shape();
    shape_9.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-9.8,24.4).lineTo(-9.8,-24.4).lineTo(-2.3,-24.4).lineTo(-2.3,17.5).lineTo(9.7,17.5).lineTo(9.7,24.4).closePath();
    shape_9.setTransform(85.3,25.075);

    const shape_10 = new createjs.Shape();
    shape_10.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-9.7,24.4).lineTo(-9.7,-24.4).lineTo(-2.4,-24.4).lineTo(-2.4,17.5).lineTo(9.7,17.5).lineTo(9.7,24.4).closePath();
    shape_10.setTransform(61.65,25.075);

    const shape_11 = new createjs.Shape();
    shape_11.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-10,24.4).lineTo(-10,-24.4).lineTo(10,-24.4).lineTo(10,-17.5).lineTo(-2.6,-17.5).lineTo(-2.6,-4.4).lineTo(6.4,-4.4).lineTo(6.4,2.5).lineTo(-2.6,2.5).lineTo(-2.6,17.5).lineTo(10,17.5).lineTo(10,24.4).closePath();
    shape_11.setTransform(37.925,25.125);

    const shape_12 = new createjs.Shape();
    shape_12.graphics.beginFill("#FFFFFF").beginStroke().moveTo(4,24.4).lineTo(4,2.4).lineTo(-4,2.4).lineTo(-4,24.4).lineTo(-11.4,24.4).lineTo(-11.4,-24.4).lineTo(-4,-24.4).lineTo(-4,-4.5).lineTo(4,-4.5).lineTo(4,-24.4).lineTo(11.4,-24.4).lineTo(11.4,24.4).closePath();
    shape_12.setTransform(11.525,25.075);

    container.addChild(shape, shape_1, shape_2, shape_3, shape_4, shape_5, shape_6, shape_7, shape_8, shape_9, shape_10, shape_11, shape_12);
    container.x = 45;
    container.y = 30;
    this.addChild(container);

  }
}
