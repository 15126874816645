interface ICanvasPropertiesDimension {
  width: number;
  height: number;
}

export class CanvasProperties {
  private width = 0;
  private height = 0;
  private stage = 0;
  private initialWidth = 0;
  private initialHeight = 0;
  private containerFrame;
  private containerFrameScale = 1;

  public getContainerFrameScale(): number {
    return this.containerFrameScale;
  }

  public setContainerFrameScale(scale: number): void {
    this.containerFrameScale = scale;
  }

  public getDimension():ICanvasPropertiesDimension {
    return {width: this.width, height: this.height};
  }

  public setDimension (w: number, h: number): void {
    this.width = w;
    this.height = h;
  }

  public getWidth(): number {
    return this.width;
  }

  public setWidth(w: number): void {
    this.width = w;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(h: number): void {
    this.height = h;
  }

  public getStage() {
    return this.stage;
  }

  public setStage(stage): void {
    this.stage = stage;
  }

  public getContainerFrame() {
    return this.containerFrame;
  }

  public setContainerFrame(frame) {
    this.containerFrame = frame;
  }

  public getInitialSize(): ICanvasPropertiesDimension {
    return {width: this.initialWidth, height: this.initialHeight};
  }

  public getInitializeWidth(): number {
    return this.initialWidth;
  }

  public getInitializeHeight(): number {
    return this.initialHeight;
  }

  public setInitialSize(w: number,h: number): void {
    this.initialWidth = w;
    this.initialHeight = h;
  }

  public setInitialWidth(w: number): void {
    this.initialWidth = w;
  }

  public setInitialHeight(h: number): void {
    this.initialHeight = h;
  }
}


