import { IRGB } from './i-color';

export const ColorRGB: {[key: string]: IRGB} =  {
  BLACK: {
    red: 0,
    green: 0,
    blue: 0
  }
}


export enum ColorHEX {
  BLACK = '#000',
  WHITE = '#FFF'
}
