import { IAddonsOptions } from './Addons';
import { getStyle } from '../Style';

export class OinionSkin {
  static instance: OinionSkin;
  private canvas: HTMLElement;
  private onionSkinImg: string = '';

  public constructor(opts: IAddonsOptions) {
    this.canvas = document.querySelector(opts.canvasId) as HTMLElement;
  }

  public showPrompt() {
    var newURL = prompt("Enter the new URL of your oinion skin", "images/template/");
    if (newURL != "" && newURL != "images/template/") {
      this.onionSkinImg = newURL as string;
      this.toggle(true);
    }
  };

  public toggle(throughPrompt: boolean = false): void {
    let background: string = getStyle(this.canvas, 'background-image');
    let bgUrl: string = this.onionSkinImg || "images/template/bg.jpg";

    if (throughPrompt || !throughPrompt && background === 'none') {
      this.canvas.style.backgroundImage = `url(${bgUrl})`;
    } else {
      this.canvas.style.backgroundImage = 'none';
    }
  };
}


