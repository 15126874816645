import { OinionSkin } from './OnionSkin';
import { DragInfos } from './DragInfos';

export interface IAddonsOptions {
  canvasId?: string;
  canvasWrapperId?: string;
}

export class Addons {
  static instance: Addons;
  private canvas:HTMLElement;
  private canvasWrapperId:string;
  private onionSkin: OinionSkin;

  private constructor(opts: IAddonsOptions) {
    let {canvasId, canvasWrapperId} = opts;
    this.canvas = document.querySelector(canvasId as string) as HTMLElement;
    this.onionSkin = new OinionSkin({canvasId});
    this.canvasWrapperId = canvasWrapperId as string;
    this.init();
  }

  public static getInstance(opts: IAddonsOptions): Addons {
    if (!Addons.instance) {
      Addons.instance = new Addons(opts);
    }
    return Addons.instance;
  }

  public get dragInfos(): DragInfos {
    return DragInfos.getInstance({canvasWrapperId: this.canvasWrapperId});
  }

  private init(): void {
    window.addEventListener('keydown', (evt: KeyboardEvent) => {
      switch (evt.keyCode) {

        // T
        case 84:
          this.onionSkin.toggle();
          break;

        // U
        case 85:
          this.onionSkin.showPrompt();
          break;

        // P
        case 80:
          this.dragInfos.handleInfosTips();
          break;

    });


    /*$("canvas").mousemove(function (event) {
      mamzelle.Addons.coords = event.pageX + ", " + event.pageY;
      ;
    });*/


  }
}


