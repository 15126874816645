interface ITrackingManagerOptions {
  enabled: boolean;
  ua: string;
}

interface ITrackingManagerEvent {
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
}

export class TrackingManager {
  static instance: TrackingManager;
  readonly enabled: boolean;
  readonly ua: string;

  private constructor(opts: ITrackingManagerOptions) {
    this.ua = opts.ua;
    this.enabled = !!opts.enabled;
    this.initialize(opts);
  }

  public static getInstance(): TrackingManager {
    if (!TrackingManager.instance) {
      TrackingManager.instance = new TrackingManager({
        enabled: Boolean(process.env.GA_ENABLE),
        ua: process.env.GA_UA as string
      });
    }
    return TrackingManager.instance;
  }

  private initialize(opts: ITrackingManagerOptions) {
    if (!this.ua) { throw new Error("TrackingManager:Initialize :: Please pass ua in options") }

    if (this.enabled) {
      this.addScript();
    } else {
      console.log('TrackingManager :: the tracker is disabled');
    }
  }

  private isGAisPresentInThePage(): boolean {
    return typeof window.ga !== "undefined";
  }

  private injectGAScriptTag(): void {
    var gaJsHost = (("https:" == document.location.protocol) ? "https://ssl." : "http://www.");
    var gaScript = document.createElement('script');
    gaScript.src = gaJsHost + "google-analytics.com/analytics.js";
    document.body.appendChild(gaScript);
  }

  private injectGAVar(): void {
    let scriptContains = "window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;";
    scriptContains += "ga('create', '" + this.ua + "', 'auto');";
    scriptContains += "ga('send', 'pageview');";
    const scriptVar = document.createElement('script');
    scriptVar.innerHTML = scriptContains;
    document.body.appendChild(scriptVar);
  }

  private addScript(): void {
    if (this.isGAisPresentInThePage()) {
      this.injectGAVar();
    }
    this.injectGAVar();
    this.injectGAScriptTag();
  }

  public sendEvent(event: ITrackingManagerEvent) {

    var props = Object.assign({ hitType: 'event'}, event);

    if(this.enabled) window.ga('send', props);
  };
}
