import { IRGB, IRGBA } from './i-color';

/**
 * Method that convert RGBA value to Hex
 * Copied from https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param red
 * @param green
 * @param blue
 * @param alpha
 */
export function convertRGBAToHex({red, green, blue, alpha}: IRGBA): string {
  let r = red.toString(16);
  let g = green.toString(16);
  let b = blue.toString(16);
  let a = Math.round(alpha * 255).toString(16);

  if (r.length == 1)
    r = "0" + r;
  if (g.length == 1)
    g = "0" + g;
  if (b.length == 1)
    b = "0" + b;
  if (a.length == 1)
    a = "0" + a;

  return "#" + r + g + b + a;
}

export function convertRGBToRGBA(rgb: IRGB, alpha: number = 1): IRGBA {
  return Object.assign({alpha}, rgb);
}


/**
 * Copied from This script was taken on http://stackoverflow.com/a/32257791
 * @param colorStart
 * @param colorEnd
 * @param colorCount
 */
export function generateHexGradient(colorStart: string, colorEnd: string, colorCount: number) {

  function hex(c) {
    var s = "0123456789abcdef";
    var i = parseInt(c);
    if (i == 0 || isNaN(c))
      return "00";
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  function convertToHex(rgb: number[]) {
    return "#" + hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  function trim(s: string) {
    return (s.charAt(0) == '#') ? s.substring(1, 7) : s
  }

  /* Convert a hex string to an RGB triplet */
  function convertToRGB(hex: string) {
    var color = [];
    color[0] = parseInt((trim(hex)).substring(0, 2), 16);
    color[1] = parseInt((trim(hex)).substring(2, 4), 16);
    color[2] = parseInt((trim(hex)).substring(4, 6), 16);
    return color;
  }

  function generateColor(colorStart: string, colorEnd: string, colorCount: number) {

    // The beginning of your gradient
    var start = convertToRGB(colorStart);

    // The end of your gradient
    var end = convertToRGB(colorEnd);

    // The number of colors to compute
    var len = colorCount;

    //Alpha blending amount
    var alpha = 0.0;

    var saida = [];

    for (let i = 0; i < len; i++) {
      var c = [];
      alpha += (1.0 / len);

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      saida.push(convertToHex(c));

    }

    return saida;

  }

  return generateColor(colorEnd, colorStart, colorCount);

};

