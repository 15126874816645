import createjs from 'createjs-module';
import Shape = createjs.Shape;
import Container = createjs.Container;
import { convertRGBAToHex, convertRGBToRGBA } from '../../../utils/Color/Color';
import { ColorRGB } from '../../../utils/Color/color-value';
import { mamzelle } from '../../../index';


export class BubbleCuriosity extends Container {

  public constructor() {
    super();
    this.drawVector();
  }

  private drawVector(): void {
    this.drawBubble();
    this.drawText();
  }

  private drawBubble() {
    const dropShadowColor = convertRGBAToHex(convertRGBToRGBA(ColorRGB.BLACK,0.1));

    const shape = new createjs.Shape();
    shape.graphics.beginLinearGradientFill(["#AE6529","#AF6529","#CD802C","#E59830","#F8A932","#FFB033"],[0,0.004,0.275,0.533,0.78,1],0,55.4,0,-55.3).s().p("AkMGHInSAAQgnAAgcgcQgcgcAAgoIAArwQAAgoAcgcQAcgcAnAAIW9AAQAnAAAcAcQAcAcAAAoIAALwQAAAogcAcQgcAcgnAAIqIAAImtCjg");
    shape.setTransform(83.025,55.375);
    shape.shadow = new createjs.Shadow(dropShadowColor, 3, 3, 20);

    this.addChild(shape);
  }

  private drawText(): void {
    const container = new createjs.Container();

    const shape = new createjs.Shape();
    shape.graphics.f("#FFFFFF").s().p("AgPA/QALgQAFgQQAGgQAAgNQAAgJgCgIIgFgSQgCgJgFgHIgIgOIAHgDQAIAMAJAVQAGASAAAQQAAALgDALQgCAKgGAMQgEAJgIANg");
    shape.setTransform(96.95,42.325);

    const shape_1 = new createjs.Shape();
    shape_1.graphics.f("#FFFFFF").s().p("AgSAsQgKgEgGgIIADgGQAIAIAIACQAHADAJAAQAMABAHgFQAHgFAAgJQAAgGgEgDQgDgEgGgBIgegIQgIgEgCgDQgDgEAAgIQAAgIAFgHQAEgFAHgEQAJgCAHAAQALAAAIADQAHADAFAGIgFAHQgEgGgHgDQgGgDgJAAIgLACQgGABgDAEQgEAGAAAFQAAAFADAEQACACAFACQAHADAFABIATAEQAHADAEAFQAEAFABAHQAAAMgKAIQgKAHgPAAQgLAAgIgDg");
    shape_1.setTransform(90.55,43.95);

    const shape_2 = new createjs.Shape();
    shape_2.graphics.f("#FFFFFF").s().p("AgYAsQgHgEgDgGQgFgIAAgHQAAgJAGgFQAFgFAIgDQAHgEANAAIANABIAOAEIAAgJQAAgMgHgIQgIgIgMABQgGAAgIACIgPAJIgDgHQAIgGAJgDQAIgDAIAAQAQAAAJAKQAKAJAAARIAAAsIABADIACABIAAAHIgEAAQgDgBgCgBQgCgDAAgDIAAgHQgHAIgJAFQgKAEgKAAQgJAAgHgDgAgWAEQgIAFAAAJQAAAGADAFQADAFAGADQAFADAHAAQAKAAAHgEQAIgDAEgHIADgEIABgEIAAgPQgFgCgJgBIgNAAQgNAAgJAEg");
    shape_2.setTransform(81.55,43.95);

    const shape_3 = new createjs.Shape();
    shape_3.graphics.f("#FFFFFF").s().p("AgCA8QgDgBgEgFQgCgDAAgGIAAhDIgNAAIAAgIIANAAIAAgfIAJAAIAAAfIAUAAIAAAIIgUAAIAABBQAAAFACADQAEADAEAAIAKgCIAEgCIADAHIgEACIgGACIgJABQgFAAgDgCg");
    shape_3.setTransform(74.325,42.45);

    const shape_4 = new createjs.Shape();
    shape_4.graphics.f("#FFFFFF").s().p("AgDBAIAAhbIAIAAIAABbgAgDgvIAAgQIAIAAIAAAQg");
    shape_4.setTransform(69.55,42.1);

    const shape_5 = new createjs.Shape();
    shape_5.graphics.f("#FFFFFF").s().p("AgSAsQgJgEgIgIIAFgGQAHAIAIACQAIADAHAAQANABAGgFQAIgFAAgJQAAgGgDgDQgGgEgEgBIgfgIQgGgDgDgEQgEgFABgHQgBgIAFgHQAEgFAJgEQAIgCAHAAQAMAAAHADQAIADAEAGIgFAHQgEgGgHgDQgHgDgJAAIgLACQgFABgEAEQgDAGAAAFQAAAFACAEQADACAGACQAEADAHABIASAEQAIADAFAFQADAFAAAHQAAAMgJAIQgKAHgPAAQgLAAgIgDg");
    shape_5.setTransform(63.5,43.95);

    const shape_6 = new createjs.Shape();
    shape_6.graphics.f("#FFFFFF").s().p("AgRAsQgIgEgGgHQgGgGgDgJQgEgIAAgKQAAgKAEgHQACgIAIgHQAGgHAHgEQAIgDAJAAQALAAAGADQAJAEAGAHQAFAGAFAJQACAIAAAJQAAAKgCAIQgFAJgFAGQgGAHgJAEQgGADgLAAQgJAAgIgDgAgRghQgIAGgFAJQgFAJAAAJQAAALAFAJQAGAJAHAFQAIAFAJAAQAKAAAIgFQAHgEAGgLQAFgJgBgKQABgKgFgIQgGgLgHgEQgJgGgJABQgJgBgIAGg");
    shape_6.setTransform(54.15,43.95);

    const shape_7 = new createjs.Shape();
    shape_7.graphics.f("#FFFFFF").s().p("AgDBAIAAhbIAHAAIAABbgAgDgvIAAgQIAHAAIAAAQg");
    shape_7.setTransform(47.15,42.1);

    const shape_8 = new createjs.Shape();
    shape_8.graphics.f("#FFFFFF").s().p("AgUAuIAAhaIAIAAIAAAWQAFgKAHgGQAIgGAIgBIADAAIADAAIAAAIQgNAAgJAIQgIAHgEAMIAAA4g");
    shape_8.setTransform(42.9,43.9);

    const shape_9 = new createjs.Shape();
    shape_9.graphics.f("#FFFFFF").s().p("AgfAkQgIgKAAgUIAAgzIAJAAIAAAyQAAARAGAIQAFAIAMAAQAHAAAHgDQAGgDAGgHQAGgGACgIIAAg4IAJAAIAABOIABADIADABIAAAIIgFAAQgDgBgBgCQgCgBgBgEIAAgOQgFAKgLAHQgLAGgLAAQgOAAgHgKg");
    shape_9.setTransform(34.325,44.025);

    const shape_10 = new createjs.Shape();
    shape_10.graphics.f("#FFFFFF").s().p("AgSA6QgKgGgJgJQgHgJgFgMQgEgMAAgKQAAgMAEgKQAEgKAIgKQAIgKAKgEQAKgGAOAAQARAAALAHQAMAIAGAMIgHAFQgFgKgGgFQgFgEgJgDQgIgCgHAAQgKAAgKAFQgJAEgGAJQgHAKgDAHQgDAKAAAJQAAAMAEAIQAFALAGAHQAIAIAHAFQAJAEAKAAQAHAAAIgCQAJgEAGgFQAHgFAEgIIAIADQgEAJgIAHQgHAGgLAEQgKADgKAAQgNAAgJgFg");
    shape_10.setTransform(23.125,42.25);

    const shape_11 = new createjs.Shape();
    shape_11.graphics.f("#FFFFFF").s().p("AgDAtQgFgKgDgMQgDgLAAgLQAAgSAHgQQAGgPAKgSIAGADIgPAeIgEASIgCARQAAANAGAQQAFAQAKAQIgGAEQgJgNgDgJg");
    shape_11.setTransform(14.825,42.325);

    const shape_12 = new createjs.Shape();
    shape_12.graphics.f("#FFFFFF").s().p("AgRB/IAAhnIgwiWIAoAAIAaBkIAahkIAmAAIgtCWIAABng");
    shape_12.setTransform(106.3,13.075);

    const shape_13 = new createjs.Shape();
    shape_13.graphics.f("#FFFFFF").s().p("AgSB/IAAjZIgpAAIAAgkIB3AAIAAAkIgqAAIAADZg");
    shape_13.setTransform(93,13.075);

    const shape_14 = new createjs.Shape();
    shape_14.graphics.f("#FFFFFF").s().p("AgSB/IAAj9IAlAAIAAD9g");
    shape_14.setTransform(83.2,13.075);

    const shape_15 = new createjs.Shape();
    shape_15.graphics.f("#FFFFFF").s().p("AgrBwQgRgTgBgfIAlgGQABAnAYAAQALAAAGgHQAHgIAAgNQAAgOgKgNQgIgKgVgUQgYgVgJgRQgKgSAAgVQAAgcAQgRQARgSAZAAQAzAAAHA9IglAGQgCgSgEgGQgFgHgLAAQgJAAgFAHQgGAHAAALQAAANAHAKQAGALAUATQAdAaAKAQQAKARAAAXQAAAdgRASQgRASgbAAQgaAAgSgTg");
    shape_15.setTransform(72.525,13.075);

    const shape_16 = new createjs.Shape();
    shape_16.graphics.f("#FFFFFF").s().p("AgsBuQgJgLgDgNQgCgOAAgcIAAhXQAAgdACgNQADgNAJgLQASgVAaAAQAcAAAQAVQAJALAEANQACANAAAdIAABXQAAAdgCANQgEANgJALQgRAVgbAAQgaAAgSgVgAgThNQgCAJAAAdIAABPQABAgACAJQAFAOANAAQAPAAAFgRQACgJAAgdIAAhPQgBgggCgJQgFgOgOAAQgOAAgFARg");
    shape_16.setTransform(57.95,13.075);

    const shape_17 = new createjs.Shape();
    shape_17.graphics.f("#FFFFFF").s().p("AgSB/IAAj9IAlAAIAAD9g");
    shape_17.setTransform(46.85,13.075);

    const shape_18 = new createjs.Shape();
    shape_18.graphics.f("#FFFFFF").s().p("AAUB/IgXhqIgSAAIAABqIgmAAIAAj9IA1AAQAgAAAPASQAPASAAAmQAAAYgFAPQgGANgOAKIAdB1gAgVgOIAMAAQAPAAAGgIQAGgKAAgUQAAgXgHgJQgFgGgPAAIgMAAg");
    shape_18.setTransform(36.175,13.075);

    const shape_19 = new createjs.Shape();
    shape_19.graphics.f("#FFFFFF").s().p("AgrBsQgJgLgEgNQgDgOAAgcIAAiqIAmAAIAACmQAAAiADAHQAFAOANAAQAQAAAEgRQABgJAAgdIAAimIAmAAIAACqQAAAdgCANQgDANgKALQgRAVgbAAQgbAAgQgVg");
    shape_19.setTransform(20.8,13.275);

    const shape_20 = new createjs.Shape();
    shape_20.graphics.f("#FFFFFF").s().p("AgrBuQgKgLgDgNQgDgNAAgeIAAhVQAAgeADgNQADgNAKgLQAQgVAbAAQAQAAAOAIQAOAHAGANQAGAKABAJQACAJAAATIAAANIgmAAIgBggQgBgJgGgGQgGgFgHAAQgPAAgEARQgCAGAAAgIAABPQAAAiADAHQAFAOANAAQAHAAAGgGQAGgFABgKIABglIAmAAIAAAOQAAAVgBAJQgCAKgGAKQgGAOgOAIQgOAIgQAAQgbAAgQgVg");
    shape_20.setTransform(5.95,13.075);

    container.addChild(shape, shape_1,shape_2, shape_3, shape_4, shape_5, shape_6, shape_7, shape_8, shape_9, shape_10,  shape_11,  shape_12,  shape_13,  shape_14,  shape_15,  shape_16, shape_17, shape_18,  shape_19,  shape_20);

    container.x = 32;
    container.y = 22;
    this.addChild(container);
  }
}
