import * as createjs from 'createjs-module';
import { CanvasProperties } from '../utils/CanvasProperties';
import { mamzelle } from '../index';
import { Container } from 'createjs-module';
import Graphics = createjs.Graphics;
import Shape = createjs.Shape;
import { Logo } from './logo/Logo';

export interface ICanvasOptions {
  width: number | string;
  height: number | string;
  canvasWrapperId: string;
  fps?: number;
}

export class Canvas {
  private fps = 24;
  private canvasWrapperId:string = '#canvasWrapper';
  private WIDTH_FRAME = 1926;
  private HEIGHT_FRAME = 407;
  private stage: any;
  private containerFrame: Container;
  private canvasProperties;
  private canvas;
  private MIN_WIDTH_FRAME = 844;
  private MIN_HEIGHT_FRAME = 500;



  public constructor(opts: ICanvasOptions): void {
    this.init(opts);
  }

  private init(opts: ICanvasOptions): void {
    this.canvasWrapperId = opts.canvasWrapperId;
    this.createStage();
    this.handleWindowResize();
  }

  private createStage() {
    if(!(!!document.createElement('canvas').getContext))
    {
      const wrapper: HTMLElement = document.getElementById(`${this.canvasWrapperId}`) as HTMLElement;
      wrapper.innerHTML = "Your browser does not appear to support the HTML5 Canvas element";
      return;
    }


    /* CANVAS */
    this.canvas = document.getElementById("animCanvas");
    const ctx = this.canvas.getContext("2d");
    this.stage = new createjs.Stage(this.canvas);

    this.canvasProperties = new CanvasProperties();
    this.canvasProperties.setStage(this.stage);

    this.containerFrame = new createjs.Container();
    this.canvasProperties.setContainerFrame(this.containerFrame);
    this.stage.addChild(this.containerFrame);


    this.handleWindowResize();


    // TEST
    let logo = new Logo();
    logo.x = 592;
    logo.y = 285;

    this.containerFrame.addChild(logo);


    createjs.Ticker.setFPS(this.fps);
    createjs.Ticker.addEventListener("tick", this.stage);

    this.setCanvasSizeForDevicePixelRatio();
  }

  private setCanvasSizeForDevicePixelRatio(): void {
    if (window.devicePixelRatio) {
      this.setCanvasSize(parseInt(this.canvas.style.width), parseInt(this.canvas.style.height));
      this.setStageScale();
    }
  }

  private setStageScale(): void {
    // set CreateJS to render scaled
    this.stage.scaleX = this.stage.scaleY = window.getDevicePixelRatio();
  }
  private setCanvasSize(width: number, height: number): void {
    // reset the canvas width and height with window.devicePixelRatio applied
    this.canvas.setAttribute('width', Math.round(width * window.getDevicePixelRatio()));
    this.canvas.setAttribute('height', Math.round( height * window.getDevicePixelRatio()));

    // force the canvas back to the original size using css
    this.canvas.style.width = width+"px";
    this.canvas.style.height = height+"px";
  }

  private centerAnimation(): void {
    let windowWidth = window.innerWidth,
        windowHeight = window.innerHeight,
        width = document.querySelector(this.canvasWrapperId).clientWidth ,
        height = document.querySelector(this.canvasWrapperId).clientHeight,
        ratio = ((width / this.WIDTH_FRAME ) <= 1 ) ? 1  : 1 ;

    if (windowWidth < this.MIN_WIDTH_FRAME) {
      ratio = 0.6;
    }

    this.setCanvasSize(windowWidth, windowHeight);

    this.canvasProperties.setDimension(windowWidth,windowHeight);

    this.containerFrame.regX = this.WIDTH_FRAME / 2 ;
    this.containerFrame.regY = this.HEIGHT_FRAME;
    this.containerFrame.x =  (windowWidth /2);
    this.containerFrame.y = height;
    this.containerFrame.scaleX = ratio;
    this.containerFrame.scaleY = ratio;



    // TEST Frame
    /*let g:Graphics = new createjs.Graphics();
    g.setStrokeStyle(1);
    g.beginStroke("#FFFFFF");
    //g.beginFill("yellow");
    g.drawRect(0,0,this.WIDTH_FRAME, this.HEIGHT_FRAME);

    let rect:Shape = new createjs.Shape(g);
    rect.x = 0;
    rect.y = 0;

    this.containerFrame.addChild(rect);*/


    this.canvasProperties.setContainerFrameScale(ratio);
  }



  // TODO :: need to handle this
  private handleWindowResize(): void {

    window.onresize = () =>{
      this.setCanvasSizeForDevicePixelRatio();
      this.centerAnimation();
    };

    this.canvasProperties.setInitialSize(this.WIDTH_FRAME,this.HEIGHT_FRAME);
    window.dispatchEvent(new Event('resize'));
  }

  window.tick = function ():void {
    this.stage.update();
  };

}


