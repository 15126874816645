import createjs from 'createjs-module';
import Shape = createjs.Shape;
import Container = createjs.Container;
import { convertRGBAToHex, convertRGBToRGBA } from '../../../utils/Color/Color';
import { ColorRGB } from '../../../utils/Color/color-value';
import { mamzelle } from '../../../index';


export class BubbleOser extends Container {

  public constructor() {
    super();
    this.drawVector();
  }

  private drawVector(): void {
    this.drawBubble();
    this.drawText();
  }

  private drawBubble() {
    const dropShadowColor = convertRGBAToHex(convertRGBToRGBA(ColorRGB.BLACK,0.1));

    const shape = new createjs.Shape();
    shape.graphics.beginLinearGradientFill(["#AE6529","#AF6529","#CD802C","#E59830","#F8A932","#FFB033"],[0,0.004,0.275,0.533,0.78,1],0,55.4,0,-55.3).beginStroke().moveTo(-26.9,39.1).lineTo(-59.5,39.1).curveTo(-63.4,39.1,-66.2,36.3).curveTo(-69,33.5,-69,29.5).lineTo(-69,-45.8).curveTo(-69,-49.8,-66.2,-52.6).curveTo(-63.4,-55.4,-59.5,-55.4).lineTo(59.5,-55.4).curveTo(63.5,-55.4,66.2,-52.6).curveTo(69,-49.8,69,-45.8).lineTo(69,29.5).curveTo(69,33.5,66.2,36.3).curveTo(63.5,39.1,59.5,39.1).lineTo(8.7,39.1).lineTo(-34.3,55.4).closePath();
    shape.setTransform(69,55.375);
    shape.shadow = new createjs.Shadow(dropShadowColor, 3, 3, 20);

    this.addChild(shape);
  }

  private drawText(): void {
    const container = new createjs.Container();

    const shape = new createjs.Shape();
    shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.4,5.6).curveTo(-0.5,4.1,0,2.8).curveTo(0.5,1.4,0.5,0.1).lineTo(0.4,-1.4).lineTo(-0.8,-4.5).lineTo(-1.5,-5.6).lineTo(-0.7,-6).curveTo(0.2,-4.6,0.8,-3).curveTo(1.5,-1.5,1.5,0.1).curveTo(1.5,1,1.2,2.1).curveTo(0.9,3.3,0.5,4.1).curveTo(0,4.9,-0.7,6).closePath();
    shape.setTransform(61.2,48.125);

    const shape_1 = new createjs.Shape();
    shape_1.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.6,3.8).curveTo(-2.4,3.5,-2.9,2.9).curveTo(-3.5,2.2,-3.7,1.6).curveTo(-4,0.9,-4,0).curveTo(-4,-0.8,-3.7,-1.6).curveTo(-3.5,-2.2,-2.9,-2.9).curveTo(-2.3,-3.6,-1.6,-3.8).curveTo(-0.9,-4.2,0,-4.2).curveTo(0.9,-4.2,1.6,-3.8).curveTo(2.2,-3.6,2.9,-2.9).curveTo(3.5,-2.2,3.7,-1.6).curveTo(4,-0.8,4,0).curveTo(4,0.9,3.7,1.6).curveTo(3.5,2.2,2.9,2.9).curveTo(2.4,3.5,1.6,3.8).curveTo(0.9,4.2,0,4.2).curveTo(-1,4.2,-1.6,3.8).closePath().moveTo(-1.5,-2.8).curveTo(-2.2,-2.3,-2.6,-1.6).curveTo(-2.9,-0.8,-2.9,0).curveTo(-2.9,0.9,-2.6,1.7).curveTo(-2.2,2.4,-1.5,2.8).curveTo(-0.9,3.2,0,3.2).curveTo(0.7,3.2,1.5,2.8).curveTo(2.1,2.4,2.5,1.6).curveTo(2.9,0.8,2.9,0).curveTo(2.9,-0.8,2.5,-1.6).curveTo(2.1,-2.4,1.5,-2.8).curveTo(0.7,-3.2,0,-3.2).curveTo(-0.8,-3.2,-1.5,-2.8).closePath();
    shape_1.setTransform(54.9,49.575);

    const shape_2 = new createjs.Shape();
    shape_2.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.6,3.8).curveTo(-2.4,3.5,-2.9,2.9).curveTo(-3.4,2.3,-3.7,1.6).curveTo(-4,0.8,-4,-0).curveTo(-4,-1,-3.5,-2.1).curveTo(-3,-3,-2.1,-3.6).curveTo(-1.1,-4.2,0,-4.2).curveTo(1.2,-4.2,2.1,-3.6).curveTo(3,-3,3.5,-2.1).curveTo(4,-1.2,4,-0).lineTo(4,0.4).lineTo(-2.9,0.4).curveTo(-2.8,1.3,-2.4,1.9).curveTo(-2.1,2.5,-1.4,3).curveTo(-0.7,3.4,0.1,3.4).curveTo(0.9,3.4,1.6,3).curveTo(2.3,2.6,2.6,1.9).lineTo(3.5,2.2).curveTo(3.2,2.7,2.7,3.2).curveTo(2.3,3.6,1.5,3.9).curveTo(0.8,4.2,0,4.2).curveTo(-0.9,4.2,-1.6,3.8).closePath().moveTo(-1.4,-3).curveTo(-2,-2.6,-2.5,-1.9).curveTo(-2.9,-1.1,-2.9,-0.4).lineTo(3,-0.4).curveTo(3,-1.2,2.6,-1.9).curveTo(2.1,-2.6,1.5,-3).curveTo(0.8,-3.3,0,-3.3).curveTo(-0.8,-3.3,-1.4,-3).closePath();
    shape_2.setTransform(45.775,49.575);

    const shape_3 = new createjs.Shape();
    shape_3.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1.8,5.4).curveTo(-2.4,5.1,-3,4.5).curveTo(-3.5,4,-3.8,3.1).curveTo(-4.1,2.4,-4.1,1.6).curveTo(-4.1,0.5,-3.7,-0.5).curveTo(-3.1,-1.4,-2.3,-2.1).curveTo(-1.4,-2.6,-0.4,-2.6).curveTo(0.6,-2.6,1.4,-2.1).curveTo(2.2,-1.5,2.6,-0.7).lineTo(2.6,-5.8).lineTo(3.7,-5.8).lineTo(3.7,4.3).lineTo(3.8,4.7).lineTo(4.1,4.8).lineTo(4.1,5.6).lineTo(3.6,5.7).curveTo(3.2,5.6,3.1,5.3).curveTo(2.8,5.2,2.8,4.8).lineTo(2.8,4).curveTo(2.3,4.8,1.5,5.3).curveTo(0.7,5.7,-0.3,5.8).curveTo(-1.1,5.7,-1.8,5.4).closePath().moveTo(-1.3,-1.4).curveTo(-1.8,-1.1,-2.2,-0.6).curveTo(-2.6,-0.2,-2.8,0.4).curveTo(-3,0.9,-3,1.6).curveTo(-3,2.3,-2.8,2.9).curveTo(-2.6,3.3,-2.2,3.9).curveTo(-1.7,4.3,-1.2,4.6).curveTo(-0.7,4.8,0,4.8).lineTo(0.8,4.7).lineTo(1.7,4.2).lineTo(2.3,3.6).lineTo(2.6,2.9).lineTo(2.6,0.4).curveTo(2.5,-0.1,2,-0.6).curveTo(1.6,-1.1,1,-1.4).curveTo(0.5,-1.6,-0.2,-1.6).curveTo(-0.8,-1.6,-1.3,-1.4).closePath();
    shape_3.setTransform(36.2,48);

    const shape_4 = new createjs.Shape();
    shape_4.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-3,3.2).curveTo(-3.6,2.4,-3.6,0.6).lineTo(-3.6,-4.1).lineTo(-2.6,-4.1).lineTo(-2.6,0.4).curveTo(-2.6,1.9,-2.1,2.5).curveTo(-1.6,3.2,-0.7,3.1).curveTo(0,3.1,0.5,2.9).curveTo(1.1,2.6,1.5,2.1).curveTo(2,1.6,2.2,1).lineTo(2.2,-4.1).lineTo(3.3,-4.1).lineTo(3.3,2.7).lineTo(3.4,3).lineTo(3.6,3.1).lineTo(3.6,3.9).lineTo(3.4,3.9).lineTo(3.1,4).lineTo(2.6,3.7).curveTo(2.4,3.5,2.4,3.2).lineTo(2.3,2.1).curveTo(1.8,3,0.9,3.6).curveTo(0,4.1,-1.1,4.1).curveTo(-2.4,4.1,-3,3.2).closePath();
    shape_4.setTransform(26.975,49.65);

    const shape_5 = new createjs.Shape();
    shape_5.graphics.beginFill("#FFFFFF").beginStroke().moveTo(3.9,5.5).lineTo(2.5,2.1).lineTo(-2.5,2.1).lineTo(-3.9,5.5).lineTo(-5.1,5.5).lineTo(-0.5,-5.5).lineTo(0.5,-5.5).lineTo(5,5.5).closePath().moveTo(-2.2,1.2).lineTo(2.2,1.2).lineTo(-0,-4.2).closePath();
    shape_5.setTransform(17.15,48.075);

    const shape_6 = new createjs.Shape();
    shape_6.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-0.4,4.1).curveTo(-0.9,3.1,-1.2,2.1).curveTo(-1.5,1.1,-1.5,0.1).curveTo(-1.5,-1.5,-0.9,-3).curveTo(-0.3,-4.4,0.7,-6).lineTo(1.5,-5.6).lineTo(0.2,-3).lineTo(-0.3,-1.4).curveTo(-0.5,-0.7,-0.5,0.1).curveTo(-0.5,1.3,0,2.8).curveTo(0.7,4.4,1.5,5.6).lineTo(0.7,6).curveTo(-0,4.9,-0.4,4.1).closePath();
    shape_6.setTransform(9.9,48.125);

    const shape_7 = new createjs.Shape();
    shape_7.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-6.8,16.7).lineTo(-6.8,-16.6).lineTo(6.8,-16.6).lineTo(6.8,-11.9).lineTo(-1.8,-11.9).lineTo(-1.8,-3).lineTo(4.4,-3).lineTo(4.4,1.7).lineTo(-1.8,1.7).lineTo(-1.8,11.9).lineTo(6.8,11.9).lineTo(6.8,16.7).closePath();
    shape_7.setTransform(65.05,16.65);

    const shape_8 = new createjs.Shape();
    shape_8.graphics.beginFill("#FFFFFF").beginStroke().moveTo(2.6,16.7).lineTo(-0.5,2.7).lineTo(-2.9,2.7).lineTo(-2.9,16.7).lineTo(-7.9,16.7).lineTo(-7.9,-16.6).lineTo(-0.9,-16.6).curveTo(3.4,-16.6,5.4,-14.4).curveTo(7.4,-12.1,7.4,-7).curveTo(7.4,-3.8,6.7,-1.9).curveTo(6,-0,4,1.4).lineTo(7.9,16.7).closePath().moveTo(-2.9,-2).lineTo(-1.3,-2).curveTo(0.2,-2,0.7,-2.2).curveTo(1.4,-2.6,1.7,-3.4).curveTo(2.4,-4.7,2.4,-7).curveTo(2.4,-9.2,1.7,-10.6).curveTo(1.4,-11.4,0.7,-11.7).curveTo(-0,-12,-1.3,-11.9).lineTo(-2.9,-11.9).closePath();
    shape_8.setTransform(46.725,16.65);

    const shape_9 = new createjs.Shape();
    shape_9.graphics.beginFill("#FFFFFF").beginStroke().moveTo(3.7,16.7).lineTo(2.6,9.3).lineTo(-2.8,9.3).lineTo(-3.9,16.7).lineTo(-8.9,16.7).lineTo(-3.4,-16.6).lineTo(3.1,-16.6).lineTo(8.9,16.7).closePath().moveTo(-2.1,4.6).lineTo(1.8,4.6).lineTo(-0.2,-8.6).closePath();
    shape_9.setTransform(26.575,16.65);

    const shape_10 = new createjs.Shape();
    shape_10.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-7.7,16.7).lineTo(-7.7,-16.6).lineTo(-0.7,-16.6).curveTo(3.7,-16.6,5.7,-14.4).curveTo(7.8,-12.1,7.8,-7.3).lineTo(7.8,7.2).curveTo(7.8,12,5.7,14.3).curveTo(3.7,16.7,-0.7,16.7).closePath().moveTo(-2.8,11.9).lineTo(-1.2,11.9).curveTo(0.9,11.9,1.9,10.6).curveTo(2.4,9.9,2.5,9).curveTo(2.7,8.2,2.7,6.3).lineTo(2.7,-6.3).curveTo(2.7,-8.2,2.5,-9.1).curveTo(2.4,-9.8,1.9,-10.7).curveTo(0.9,-12,-1.2,-11.9).lineTo(-2.8,-11.9).closePath();
    shape_10.setTransform(7.75,16.65);

    container.addChild(shape, shape_1,shape_2, shape_3, shape_4, shape_5, shape_6, shape_7, shape_8, shape_9, shape_10);

    container.x = 32;
    container.y = 22;
    this.addChild(container);
  }
}
