import { IAddonsOptions } from './Addons';
import DisplayObject = createjs.DisplayObject;
import Container = createjs.Container;
import * as createjs from 'createjs-module';

export class DragInfos {
  static instance: DragInfos;
  private infoTipsVisible: boolean = true;
  private canvasWrapperId: string;
  private offset: any;


  private constructor(opts:IAddonsOptions) {
    this.canvasWrapperId = opts.canvasWrapperId as string;
    this.injectCssStyle();
  }

  private injectCssStyle() {
    console.log('inject');

    let css: string = `
       .addons-infostips {
      background:#ffec6e;
      font-family: Arial;
      font-size: 12px;
      color: #000;
      padding:5px;
      border: 1px solid #e3c500;
      position:absolute;
      top:0;
      z-index: 100 !important;
    }
    `;
    let head = document.head || document.getElementsByTagName('head')[0];
    let style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }

  public static getInstance(opts:IAddonsOptions): DragInfos {
    if (!DragInfos.instance) {
      DragInfos.instance = new DragInfos(opts);
    }
    return DragInfos.instance;
  }

  public addDrag(...args: DisplayObject[]): void {
    args.forEach((displayObject) => {
      this.createInfoTip(displayObject);


      displayObject.parent.on("mousedown", (evt:any) => {
        const  o = evt.currentTarget;
        const offset = {x: o.x - evt.stageX, y: o.y - evt.stageY};

        displayObject.parent.on("pressmove", (ev: any) =>{
          this.moveObj(ev, offset);
        });
      });

    });
  }

  private get tooltipClass():string {
    return 'addons-infostips';
  }

  private createContainer(obj: DisplayObject) {
    const parent = obj.parent,
      lastX = obj.x,
      lastY = obj.y;
    parent.removeChild(obj);

    const container = new createjs.Container();

    container.x = lastX;
    container.y = lastY;
    container.rotation = obj.rotation;

    obj.x = obj.y = obj.rotation = 0;

    parent.addChild(container);
    container.addChildAt(obj, 0);

    return container;
  }

  private createTrackPoint(obj: Container) {
    var s = new createjs.Shape();

    s.graphics.beginFill("#FFFFFF");
    s.graphics.drawCircle(0, 0, 10, 10);
    s.graphics.endFill();

    s.addEventListener("mousedown", (evt) => {
      this.rotateObj(evt);
    });

    obj.addChild(s);
  }

  private get infosTip(): HTMLElement {
    return document.querySelector('.addons-infostips') as HTMLElement;
  }

  public handleInfosTips(): void {
    if (this.infoTipsVisible) {
      this.infosTip.style.display = 'none';
      this.infoTipsVisible = false;
    } else {
      this.infosTip.style.display = 'block';
      this.infoTipsVisible = true;
    }
  }


  private createInfoTip(obj: DisplayObject) {
    var t = this,
      currentX = obj.x,
      currentY = obj.y,
      currentRotation = obj.rotation,
      name = obj.name || "";

    let infoTips = document.createElement("div");
    infoTips.classList.add(this.tooltipClass);
    infoTips.innerHTML = `${name}</br> ${currentX}, ${currentY}, ${currentRotation}`;
    document.querySelector(this.canvasWrapperId).appendChild(infoTips);

    var content = new createjs.DOMElement(infoTips);
    content.name = "addons-infoTips";

    const container = t.createContainer(obj);
    container.addChild(content);
  }

  private updateInfoTip(evt: Container) {

    let currentObj = evt.currentTarget,
      currentX = currentObj.x,
      currentY = currentObj.y,
      currentRotation = currentObj.rotation,
      label = currentObj.getChildByName("addons-infoTips"),
      name = evt.target.name || "";


    label.htmlElement.classList.add(this.tooltipClass);
    label.htmlElement.innerHTML = `${name} <br/> ${currentX}  ,  ${currentY} , ${currentRotation}`;
  }

  private moveObj(evt: any, offset: any): void {
    let o: DisplayObject = evt.currentTarget as Container;

    o.x = evt.stageX + offset.x;
    o.y = evt.stageY + offset.y;

    this.updateInfoTip(evt);
  }

}


