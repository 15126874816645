import { TrackingManager } from './utils/TrackingManager';
import { Canvas } from './canvas/canvas';
import { Addons } from './utils/addons/Addons';
import { randomBetweenMinAndMax } from './utils/addons/Math';
import { gsap, CSSPlugin } from 'gsap';
import { CanvasEventType } from './canvas/canvas-event-values';

declare global {
  interface Window {
    ga: any;
    getDevicePixelRatio: any;
    tick: any;
  }
}
export let mamzelle: any = {};

function initialize(): void {
  let canvasId = '#animCanvas';
  let canvasWrapperId = '#canvasWrapper';

  mamzelle.TrackingManager = TrackingManager.getInstance();
  mamzelle.Addons = Addons.getInstance({
    canvasId,
    canvasWrapperId
  });


  new Canvas({
    width: '100vh',
    height: 'auto',
    fps: 24,
    canvasWrapperId
  });

  modifyBackground();
  bindEvents();
  startAnimation();
  add100vhFix();
}

function add100vhFix() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
    '--mobile-100vh',
    `${vh}px`
  );

  const vw = window.innerWidth * 0.01;
  document.documentElement.style.setProperty(
    '--mobile-100vw',
    `${vw}px`
  );
}

function bindEvents() {
  bindTrackingSocialMedia();
  bindWindowResize();
}

function bindWindowResize() {
  window.addEventListener('resize', () => {
    add100vhFix();
  });
}

function bindTrackingSocialMedia() {
  document.querySelector('.mamzelle-logo-media-item__link')!.addEventListener('click', ($event) => {
    const eventCategory = $event.currentTarget.getAttribute('data-event-category');
    const eventLabel = $event.currentTarget.getAttribute('data-event-label');

    mamzelle.TrackingManager.sendEvent({
      eventCategory,
      eventLabel,
      eventAction: 'click'
    })
  });
}

function modifyBackground() {
  let maximum = 7;
  let minimum = 1;
  var id = randomBetweenMinAndMax(minimum, maximum);

  document.querySelector('.mamzelle-logo__top__background').classList.add(`background${id}`);
}

function startAnimation() {
  gsap.to('.mamzelle-logo__bottom', {ease: "circ.inOut",opacity: 1, duration: 0.8, delay: 1, transform: 'translate3d(0,0,0)'});
  gsap.to('.mamzelle-logo__bottom__inner', {opacity: 1, duration: 1.6, delay: 3.5, transform: 'translate3d(0,0,0)'});
  gsap.to(".mamzelle-logo__top__shadow", {opacity: 1, duration: 0.8, delay: 1.6});
  gsap.to('.mamzelle-logo__top__background', {opacity: 1, duration: 1.6, delay: 1.6 , onStart: () => {
      const event = new Event( CanvasEventType.LOGO_CIRCLE_ANIMATION_IN);
      document.dispatchEvent(event);
    }});
}

window.getDevicePixelRatio = function () {
  var ratio = 1;

  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  }
  return ratio;
};

window.onload = () => {
  initialize();
}





