import createjs from 'createjs-module';
import Container = createjs.Container;
import { Bubble } from './Bubble';
import { convertRGBAToHex, convertRGBToRGBA } from '../../../utils/Color/Color';
import { ColorHEX, ColorRGB } from '../../../utils/Color/color-value';
import { randomBetweenMinAndMax } from '../../../utils/addons/Math';

export class BubbleIcon extends Container{

  private emojis = ['🙏🏻','✌🏻', '💪🏻', '👍🏻', '🧗🏻‍♀️', '✨'];

  public constructor() {
    super();
    this.drawVector();
  }

  private drawVector(): void {
    const emoji = this.emojis[randomBetweenMinAndMax(0, this.emojis.length-1)];
    const dropShadowColor = convertRGBAToHex(convertRGBToRGBA(ColorRGB.BLACK,0.5));
    const bubble = new Bubble({
      colorStart: '#3ad096',
      colorEnd: '#108759'
    });
    bubble.shadow = new createjs.Shadow(dropShadowColor, 1, 1, 4);

    const text = new createjs.Text(emoji, "80px Segoe UI Emoji", ColorHEX.WHITE);
    text.x = 70;
    text.y = 100;
    text.textBaseline = "alphabetic";
    text.cache(-70,-100,200, 200)

    this.addChild(bubble, text);
  }
}
